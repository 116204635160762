import { Recipe, RecipeSimple, RecipeSimpleWithMeta, RecipeWithMeta } from '@/recipe/model/recipe'
import { Pagination } from '@/shared/model/pagination'

export default interface RecipeApi {
  create: (Recipe: Recipe) => Promise<string>
  update: (id: string, Recipe: Recipe) => Promise<void>
  delete: (id: string) => Promise<void>
  get: (id: string) => Promise<RecipeWithMeta>
  getAll: () => Promise<RecipeSimple[]>
  search: (
    search: string,
    searchScope: SearchScope,
    pagination: Pagination<RecipeSimple>
  ) => Promise<RecipeSimpleWithMeta[]>
  counter: () => Promise<number>
}

export enum SearchScope {
  GROUP = 'GROUP',
  PUBLIC = 'PUBLIC'
}

import VictualApi from './api'
import Victual, { VictualOverwrite, VictualTracing, VictualWithMeta } from '@/victual/model/victual'
import {
  victualsCollection,
  counterCollection,
  victualsTracingCollection
} from '@/firebaseConfig'
import firebase from 'firebase/app'
import { Pagination } from '@/shared/model/pagination'
import { userStore } from '@/auth/state'
import { VictualFire, VictualFireMetaData } from '../model/victualFire'
import { toVictual, toVictualFireWithoutMeta, toVictualWithMeta } from '../model/converter'
import Timestamp = firebase.firestore.Timestamp

const currentUserId = userStore.state.currentUser.id
const currentGroupId = userStore.state.currentUser.group

export const FIREBASE_ARRAY_OPERATION_MAX_PARAMETERS = 10

class VictualFirebaseApi implements VictualApi {
  async create (victual: Victual): Promise<string> {
    const victualFireWithoutMeta = toVictualFireWithoutMeta(victual)
    const victualFire: VictualFire = {
      ...victualFireWithoutMeta,
      meta: this.generateFireMeta()
    }

    const doc = await victualsCollection.add(victualFire)
    return doc.id
  }

  async update (id: string, victual: Victual): Promise<void> {
    const isAllowedToUpdate = await this.isAllowedToUpdate(id, currentGroupId)
    if (!isAllowedToUpdate) {
      throw new Error(`For user with group id ${currentGroupId} it is not allowed to update victual with id ${id}`)
    }

    const victualFire = toVictualFireWithoutMeta(victual)

    return await victualsCollection.doc(id).update({
      ...victualFire,
      'meta.lastModifiedAt': Timestamp.now(),
      'meta.lastModifiedByUserId': currentUserId
    })
  }

  async updateOverwrite (id: string, victual: Victual): Promise<void> {
    const overwrite: VictualOverwrite = {
      pricePer100g: victual.pricePer100g
    }

    const key = `overwrite.${currentGroupId}`

    return await victualsCollection.doc(id).update({
      [key]: overwrite
    })
  }

  async delete (id: string): Promise<void> {
    const doc = await victualsCollection.doc(id).get()
    if (!doc.exists) {
      throw new Error(`Victual with ID ${id} not found.`)
    }

    const victualFire = doc.data() as VictualFire
    victualFire.meta.deletedByUserId = currentUserId
    victualFire.meta.deletedAt = Timestamp.now()
    await victualsCollection.doc(id).update(victualFire)

    return await victualsCollection.doc(id).delete()
  }

  async get (id: string): Promise<VictualWithMeta> {
    const doc = await victualsCollection
      .doc(id)
      .get()

    if (!doc.exists) {
      throw new Error(`Victual with ID ${id} not found.`)
    }

    const victualFire = doc.data() as VictualFire
    const victual = toVictualWithMeta(victualFire, id)

    if (this.hasOverwrite(doc.data(), currentGroupId)) {
      const overwrite = this.getOverwrite(doc.data(), currentGroupId)
      victual.pricePer100g = overwrite.pricePer100g
    }

    return victual
  }

  async getBatch (ids: string[]): Promise<Victual[]> {
    const victuals: Victual[] = []

    for (let i = 0; i < ids.length; i = i + 10) {
      // firestore query 'in' only supports 10 array members
      const victualIdsForQuery = ids.slice(i, i + 10)
      const snaps = await victualsCollection
        .where(firebase.firestore.FieldPath.documentId(), 'in', victualIdsForQuery)
        .get()

      snaps.forEach(function (doc) {
        const vic = toVictual(doc.data() as VictualFire, doc.id)
        victuals.push(vic)
      })
    }
    return victuals
  }

  async getAll (): Promise<Victual[]> {
    const snaps = await victualsCollection.get()
    return snaps.docs.map(doc => toVictual(doc.data() as VictualFire, doc.id))
  }

  async search (
    search: string,
    categories: string[],
    pagination: Pagination<Victual>
  ): Promise<VictualWithMeta[]> {
    const fromBegin = ''
    const lastItem = pagination?.last?.name.toLowerCase()

    const searchLow = search.toLocaleLowerCase()
    let query = victualsCollection
      .where('meta.nameLowerCase', '>=', searchLow)
      .where('meta.nameLowerCase', '<=', searchLow + '\uf8ff')

    if (categories.length > FIREBASE_ARRAY_OPERATION_MAX_PARAMETERS) {
      throw new Error(
        `Firebase only supports only up to ${FIREBASE_ARRAY_OPERATION_MAX_PARAMETERS}` +
        `for array operations. Found ${categories.length} categories in query.`
      )
    }
    if (categories.length > 0) {
      query = query.where('category', 'in', categories)
    }

    const snaps = await query
      .orderBy('meta.nameLowerCase')
      .startAfter(lastItem ?? fromBegin)
      .limit(pagination.pageSize)
      .get()

    return snaps.docs.map(doc => toVictualWithMeta(doc.data() as VictualFire, doc.id))
  }

  async counter (): Promise<number> {
    const doc = await counterCollection.doc('victuals').get()

    if (!doc.exists) {
      throw new Error('Could not find counter for victuals.')
    }

    return doc.data()?.count as number
  }

  async getUsageInRecipes (victualId: string): Promise<VictualTracing[]> {
    const snaps = await victualsTracingCollection
      .where('victualId', '==', victualId)
      .get()

    return snaps.docs.map(doc => doc.data() as VictualTracing)
  }

  generateFireMeta (): VictualFireMetaData {
    return {
      createdAt: Timestamp.now(),
      createdByUserId: currentUserId,
      lastModifiedAt: Timestamp.now(),
      lastModifiedByUserId: currentUserId,
      ownedByGroupId: currentGroupId
    }
  }

  hasOverwrite (docData: firebase.firestore.DocumentData | undefined, groupId: string): boolean {
    return docData?.overwrite?.[groupId] !== undefined
  }

  getOverwrite (docData: firebase.firestore.DocumentData | undefined, groupId: string): VictualOverwrite {
    let pricePer100g = docData?.overwrite?.[groupId]?.pricePer100g
    if (pricePer100g === undefined || isNaN(Number(pricePer100g))) {
      pricePer100g = 0
    }

    return {
      pricePer100g: pricePer100g
    }
  }

  async isAllowedToUpdate (vicId: string, currentGroupId: string): Promise<boolean> {
    const victual = await this.get(vicId)
    return currentGroupId === victual.meta.ownedByGroupId
  }
}

const victualFirebaseApi = new VictualFirebaseApi()
export { victualFirebaseApi }

import { victualFirebaseApi } from '@/victual/api/firebaseApi'
import { Ingredient, IngredientGroup, Recipe, RecipeMetaData, RecipeSimpleWithMeta, RecipeWithMeta } from './recipe'
import { FireIngredientGroup, RecipeFire, RecipeFireMetaData, RecipeFireWithoutMeta } from './recipeFire'

export async function toRecipeWithMeta (recipeFire: RecipeFire, id: string): Promise<RecipeWithMeta> {
  const ingGroups: IngredientGroup[] = []
  for (const fireGroup of recipeFire.ingredientGroups) {
    const group = await mapIngGroup(fireGroup)
    ingGroups.push(group)
  }

  return {
    id: id,
    name: recipeFire.name,
    directions: recipeFire.directions,
    description: recipeFire.description,
    servingSize: recipeFire.servingSize,
    ingredientGroups: ingGroups,
    meta: toRecipeMetaData(recipeFire.meta)
  }
}

async function mapIngGroup (
  fireIngGroup: FireIngredientGroup
): Promise<IngredientGroup> {
  const allVicIDs = fireIngGroup.ingredients.map((ing) => ing.victualId)
  const victuals = await victualFirebaseApi.getBatch(allVicIDs)

  const allIng: Ingredient[] = []
  fireIngGroup.ingredients.forEach((ing) => {
    const vic = victuals.find((x) => x.id === ing.victualId)

    if (vic === undefined) {
      // eslint-disable-next-line no-console
      console.log(
          `Skip victual ${ing.victualId}; it's not present in the db!`
      )
      return
    }

    allIng.push({
      amountInGrams: ing.amountInGrams,
      displayedUnit: ing.displayedUnit,
      victual: vic
    })
  })

  return {
    name: fireIngGroup.name,
    ingredients: allIng
  }
}

export function toRecipeSimpleWithMeta (recipeFire: RecipeFire, id: string): RecipeSimpleWithMeta {
  if (recipeFire.meta.createdAt === undefined) {
    console.log('undefined for recipe with name ' + recipeFire.name)
  }

  return {
    id: id,
    name: recipeFire.name,
    description: recipeFire.description,
    servingSize: recipeFire.servingSize,
    meta: toRecipeMetaData(recipeFire.meta)
  }
}

function toRecipeMetaData (meta: RecipeFireMetaData): RecipeMetaData {
  return {
    createdAt: meta.createdAt.toDate(),
    createdByUserId: meta.createdByUserId,
    lastModifiedAt: meta.lastModifiedAt.toDate(),
    lastModifiedByUserId: meta.lastModifiedByUserId,
    ownedByGroupId: meta.ownedByGroupId
  }
}

export function toRecipeFireWithoutMeta (recipe: Recipe): RecipeFireWithoutMeta {
  const ingGroup = recipe.ingredientGroups.map((g) =>
    mapFireIngGroup(g)
  )
  return {
    name: recipe.name,
    description: recipe.description,
    directions: recipe.directions,
    servingSize: recipe.servingSize,
    ingredientGroups: ingGroup
  }
}

function mapFireIngGroup (ingGroup: IngredientGroup): FireIngredientGroup {
  return {
    name: ingGroup.name,
    ingredients: ingGroup.ingredients.map((ing) => {
      const fireIngredient = {
        amountInGrams: ing.amountInGrams,
        displayedUnit: ing.displayedUnit,
        victualId: ing.victual.id
      }
      removeUndefinedDisplayedUnit(fireIngredient)

      return fireIngredient
    })
  }
}

function removeUndefinedDisplayedUnit (ing: { displayedUnit?: string }): void {
  if (ing.displayedUnit === undefined) {
    delete ing.displayedUnit
  }
}

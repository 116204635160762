import RecipeApi, { SearchScope } from './api'
import {
  recipesCollection,
  counterCollection
} from '@/firebaseConfig'

import {
  Recipe,
  RecipeSimple,
  RecipeSimpleWithMeta,
  RecipeWithMeta
} from '@/recipe/model/recipe'
import { RecipeFire, RecipeFireMetaData } from '../model/recipeFire'
import { Pagination } from '@/shared/model/pagination'
import { userStore } from '@/auth/state'
import { toRecipeFireWithoutMeta, toRecipeSimpleWithMeta, toRecipeWithMeta } from '../model/converter'
import firebase from 'firebase'
import Timestamp = firebase.firestore.Timestamp

const UNKNOWN = 'unknown'
const currentUserId = userStore.state.currentUser.id
const currentGroupId = userStore.state.currentUser.group

class RecipeFirebaseApi implements RecipeApi {
  async create (recipe: Recipe): Promise<string> {
    const recipeFireWithoutMeta = toRecipeFireWithoutMeta(recipe)

    const recipeFire: RecipeFire = {
      ...recipeFireWithoutMeta,
      meta: this.generateFireMeta()
    }

    const doc = await recipesCollection.add(recipeFire)

    return doc.id
  }

  async update (id: string, recipe: Recipe): Promise<void> {
    const recipeFire = toRecipeFireWithoutMeta(recipe)

    return await recipesCollection.doc(id).update({
      ...recipeFire,
      'meta.lastModifiedAt': Timestamp.now(),
      'meta.lastModifiedByUserId': currentUserId
    })
  }

  async delete (id: string): Promise<void> {
    const doc = await recipesCollection.doc(id).get()
    if (!doc.exists) {
      throw new Error(`Recipe with ID ${id} not found.`)
    }

    const recipeFire = doc.data() as RecipeFire
    recipeFire.meta.deletedByUserId = currentUserId
    recipeFire.meta.deletedAt = Timestamp.now()
    await recipesCollection.doc(id).update(recipeFire)

    return await recipesCollection.doc(id).delete()
  }

  async get (id: string): Promise<RecipeWithMeta> {
    const doc = await recipesCollection.doc(id).get()
    if (!doc.exists) {
      throw new Error(`Recipe with ID ${id} not found.`)
    }

    const recipeFire = doc.data() as RecipeFire
    const recipe = await toRecipeWithMeta(recipeFire, id)
    return recipe
  }

  async getAll (): Promise<RecipeSimple[]> {
    const querySnapshot = await recipesCollection.get()

    return querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        name: doc.data().name,
        description: doc.data().description,
        servingSize: doc.data().servingSize
      }
    })
  }

  async search (
    search: string,
    searchScope: SearchScope,
    pagination: Pagination<RecipeSimple>
  ): Promise<RecipeSimpleWithMeta[]> {
    const fromBegin = ''
    const lastItem = pagination?.last?.name.toLowerCase()
    const searchString = search.toLowerCase()

    let query = recipesCollection
      .where('meta.nameLowerCase', '>=', searchString)
      .where('meta.nameLowerCase', '<=', searchString + '\uf8ff')

    if (searchScope === SearchScope.GROUP) {
      const groupId = currentGroupId
      if (groupId !== UNKNOWN) {
        query = query.where('meta.ownedByGroupId', '==', groupId)
      } else {
        console.error(
          'Unable to resolve group, use public scope for search instead.'
        )
      }
    }

    query = query
      .orderBy('meta.nameLowerCase')
      .startAfter(lastItem ?? fromBegin)
      .limit(pagination.pageSize)

    const snap = await query.get()
    const recipes: RecipeSimpleWithMeta[] = snap.docs
      .map(doc => {
        const recipeFire = doc.data() as RecipeFire
        return toRecipeSimpleWithMeta(recipeFire, doc.id)
      })

    return recipes
  }

  async counter (): Promise<number> {
    const doc = await counterCollection.doc('recipes').get()

    if (!doc.exists) {
      throw new Error('Could not find counter for recipes.')
    }

    return doc.data()?.count as number
  }

  generateFireMeta (): RecipeFireMetaData {
    return {
      createdAt: Timestamp.now(),
      createdByUserId: currentUserId,
      lastModifiedAt: Timestamp.now(),
      lastModifiedByUserId: currentUserId,
      ownedByGroupId: currentGroupId
    }
  }
}

const recipeFirebaseApi = new RecipeFirebaseApi()
export { recipeFirebaseApi }

export default interface Victual {
  id: string
  name: string
  nutriments: NutrimentsPer100g
  allergens: Allergen[]
  category: VictualCategory | undefined
  supermarketDepartment: SupermarketDepartment | undefined
  defaultAmountInGrams: number
  units: VictualUnit[]
  pricePer100g: number
}

export type VictualWithMeta = Victual & { meta: VictualMetaData }

export interface NutrimentsPer100g {
  energyInKcal: number
  fats: number
  proteins: number
  carbs: number
}

export interface VictualOverwrite {
  pricePer100g: number
}

export enum VictualCategory {
  FISH_SEAFOOD = 'fish_seafood',
  SAUSAGES = 'sausages',
  SPICES = 'spices',
  DRINKS_WITHOUT_ALC = 'drinks_without_alc',
  FRUITS = 'fruits',
  SWEETS = 'sweets',
  VEGETABLES = 'vegetables',
  DRINKS_WITH_ALC = 'drinks_with_alc',
  CEREALS_RICE = 'cereals_rice',
  CAKE_PASTRY = 'cake_pastry',
  MILK_PRODUCTS = 'milk_products',
  LEGUMES_NUTS_SEEDS = 'legumes_nuts_seeds',
  VENISON_POULTRY_INNARDS = 'venison_poultry_innards',
  POTATOES_MUSHROOMS = 'potatoes_mushrooms',
  FATS = 'fats',
  BREAD_COOKY = 'bread_cooky',
  EGS_PRODUCTS_PASTA = 'egs_products_pasta',
  BEEF_VEAL_PORK_MUTTON = 'beef_veal_pork_mutton',
  MOSTLY_ANIMAL_PRODUCTS = 'mostly_animal_products',
  MOSTLY_PLANT_PRODUCTS = 'mostly_plant_products'
}

// https://www.bmel.de/DE/themen/ernaehrung/lebensmittel-kennzeichnung/pflichtangaben/allergenkennzeichnung.html
export enum Allergen {
  GLUTEN = '1_gluten',
  CRUSTACEAN = '2_crustacean',
  EGG = '3_egg',
  FISH = '4_fish',
  PEANUT = '5_peanut',
  SOY = '6_soy',
  MILK_PRODUCT_INCL_LACTOSE = 'milk_product_incl_lactose',
  NUT = '8_nut',
  CELERY = '9_celery',
  MUSTARD = '10_mustard',
  SESAME = '11_sesame',
  SULFITE = '12_sulfite',
  LUPINE = '13_lupine',
  MOLLUSC = '14_mollusc',
}

export interface VictualUnit {
  name: string
  oneUnitEqualsGrams: number
}

export interface VictualMetaData {
  createdAt: Date
  createdByUserId: string
  lastModifiedAt: Date
  lastModifiedByUserId: string
  ownedByGroupId: string
}

export enum SupermarketDepartment {
  FRUITS_VEGETABLES_HERBS_FRESH = 'fruits_vegetables_herbs_fresh',
  SPICES_DRIED = 'spices_dried',
  VINEGAR_OIL = 'vinegar_oil',
  CANS = 'cans',
  FROZEN = 'frozen',
  MEAT_FISH_FRESH = 'meat_fish_fresh',
  MILK_PRODUCTS = 'milk_products',
  FATS = 'fats',
  EGGS = 'eggs',
  LIQUOR_WINE = 'liquor_wine',
  NUTS_SEEDS_GRAINS = 'nuts_seeds_grains',
  COFFEE_TEE = 'coffee_tee',
  SUGAR = 'sugar',
  BREAD_PASTRIES = 'bread_pastries',
  BAKING_EXCIPIENT = 'baking_excipient',
  FLOUR_NUTRITIONS = 'flour_nutritions',
  RICE_LEGUMES = 'rice_legumes',
  NOODLES = 'noodles',
  BEER = 'beer',
  NONALCOHOLIC_DRINKS = 'nonalcoholic_drinks',
  SWEETS = 'sweets',
  OTHER = 'other'
}
export const defaultSupermarketDepartment = SupermarketDepartment.OTHER

export function getDepartmentOrder (department: string | undefined): number {
  switch (department) {
    case SupermarketDepartment.FRUITS_VEGETABLES_HERBS_FRESH:
      return 10
    case SupermarketDepartment.SPICES_DRIED:
      return 20
    case SupermarketDepartment.VINEGAR_OIL:
      return 30
    case SupermarketDepartment.CANS:
      return 40
    case SupermarketDepartment.FROZEN:
      return 50
    case SupermarketDepartment.MEAT_FISH_FRESH:
      return 60
    case SupermarketDepartment.MILK_PRODUCTS:
      return 70
    case SupermarketDepartment.FATS:
      return 80
    case SupermarketDepartment.EGGS:
      return 90
    case SupermarketDepartment.LIQUOR_WINE:
      return 100
    case SupermarketDepartment.NUTS_SEEDS_GRAINS:
      return 110
    case SupermarketDepartment.COFFEE_TEE:
      return 120
    case SupermarketDepartment.SUGAR:
      return 130
    case SupermarketDepartment.BREAD_PASTRIES:
      return 140
    case SupermarketDepartment.BAKING_EXCIPIENT:
      return 150
    case SupermarketDepartment.FLOUR_NUTRITIONS:
      return 160
    case SupermarketDepartment.RICE_LEGUMES:
      return 170
    case SupermarketDepartment.NOODLES:
      return 180
    case SupermarketDepartment.BEER:
      return 190
    case SupermarketDepartment.NONALCOHOLIC_DRINKS:
      return 200
    case SupermarketDepartment.SWEETS:
      return 210
    case SupermarketDepartment.OTHER:
    default:
      return 300
  }
}

export interface VictualTracing {
  recipeId: string
  recipeName: string
}

import Victual, { VictualMetaData, VictualWithMeta } from './victual'
import { VictualFire, VictualFireMetaData, VictualFireWithoutMeta } from './victualFire'

export function toVictualWithMeta (victualFire: VictualFire, id: string): VictualWithMeta {
  return {
    ...toVictual(victualFire, id),
    meta: toVictualMetaData(victualFire.meta)
  }
}

export function toVictual (victualFire: VictualFire, id: string): Victual {
  return {
    id: id,
    name: victualFire.name,
    nutriments: victualFire.nutriments,
    allergens: victualFire.allergens,
    category: victualFire.category,
    supermarketDepartment: victualFire.supermarketDepartment,
    defaultAmountInGrams: victualFire.defaultAmountInGrams,
    units: victualFire.units,
    pricePer100g: victualFire.pricePer100g
  }
}

export function toVictualFireWithoutMeta (victual: Victual): VictualFireWithoutMeta {
  const victualFire = {
    name: victual.name,
    nutriments: victual.nutriments,
    allergens: victual.allergens,
    category: victual.category,
    supermarketDepartment: victual.supermarketDepartment,
    defaultAmountInGrams: victual.defaultAmountInGrams,
    units: victual.units,
    pricePer100g: victual.pricePer100g
  }

  if (victualFire.category === undefined) {
    delete victualFire.category
  }
  if (victualFire.supermarketDepartment === undefined) {
    delete victualFire.supermarketDepartment
  }

  return victualFire
}

function toVictualMetaData (meta: VictualFireMetaData): VictualMetaData {
  return {
    createdAt: meta.createdAt.toDate(),
    createdByUserId: meta.createdByUserId,
    lastModifiedAt: meta.lastModifiedAt.toDate(),
    lastModifiedByUserId: meta.lastModifiedByUserId,
    ownedByGroupId: meta.ownedByGroupId
  }
}
